<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담일정</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <crm-datepicker class="dp-block mg-auto mb-10" id="consult_schedule_date" v-model="consStartDate" :parentDate="consStartDate" format="yyyy-MM-dd" :language="$store.state.ko">
                    </crm-datepicker>
                    <ul class="month pl-40">
                        <li><a class="pointer" @click="prevDay()"><img src="@/assets/images/pre_active.png"></a></li>
                        <!-- <li><a href=""><img src="../images/pre_active.png"></a></li> -->
                        <!--이전 사항이 있을 때-->
                        <li>
                            <p>
                                {{todayYear}}.{{todayMonth}}.{{todayDay}}
                                (
                                {{todayDayOfWeek}} )
                            </p>
                        </li>
                        <li><a class="pointer" @click="nextDay()"><img src="@/assets/images/next_active.png"></a></li>
                        <!-- <li><a href=""><img src="../images/next_active.png"></a></li> -->
                        <!--다음 사항이 있을 때-->
                        <!-- <li>
                            <crm-datepicker id="consult_schedule_date" v-model="consStartDate" :parentDate="consStartDate" format="yyyy-MM-dd" :language="$store.state.ko">
                            </crm-datepicker>
                        </li> -->
                    </ul>
                    <div class="btns mb-30">
                        <a class="btn_new2 pointer" @click="register()">상담예약등록</a>
                    </div>

                    <div class="con">
                        <table class="eventTable mt-20">
                            <tbody v-if="loading">
                                <tr>
                                    <th width="80" class="passClass">시간</th>
                                </tr>
                                <tr>
                                    <th>
                                        검색중입니다.
                                    </th>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th width="80" class="passClass">시간

                                    </th>
                                    <th width="80" v-for="(item, index) of counselorList" :key="index">{{item.name}}</th>
                                </tr>
                                <tr v-for="(item, index) of timeTable" :key="index">
                                    <td class="back">{{item.time}}</td>

                                    <td v-for="(subItem, idx) of item.timeTable" :key="idx" @click="preview(subItem)" class="pointer" :class="{'reservation': subItem.reservationStatus === '예약', 'reservation_submit': subItem.reservationStatus === '입실'
                                    , 'reservation_cancle': subItem.reservationStatus === '당일취소' || subItem.reservationStatus === '당일변경'
                                    || subItem.reservationStatus === 'NS', 'staff_off': !subItem.workTime}">
                                        <span v-if="subItem.use">[{{subItem.companyName}}]</span>
                                        <span v-else></span>
                                        <br>{{subItem.name}}
                                        <span v-if="subItem.consNum === 1"><br> (첫상담)</span></td>

                                    <!-- <td class="pointer 10 09:00 1"></td>

                                    <td class="pointer 11 09:00 2"></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <preview @modifyPreview="modifyPreview" :onModal.sync="onModal" :idxCrmReservation="idxCrmReservation" :reservationStatus="reservationStatus" :clientType="clientType" :idxCrmClient="idxCrmClient" :idxCrmCompany="idxCrmCompany"></preview>
    <register-modal ref="registerModal" :onRegisterModal.sync="onRegisterModal" :consStartDate.sync="consStartDate" :idxCrmStaff.sync="idxCrmStaff" :staffName.sync="staffName" :time.sync="time"></register-modal>
    <modify-modal ref="modifyModal" :onModifyModal.sync="onModifyModal"></modify-modal>
</div>
</template>

<script>
import Preview from '@/pages/convention/consult/consultScheduleButton.vue'
import registerModal from '@/pages/convention/consult/consultScheduleRegister.vue'
import modifyModal from '@/pages/convention/consult/consultScheduleModify.vue'
export default {

    components: {
        Preview,
        registerModal,
        modifyModal
    }, 

    data: () => ({
        time: '',
        onRegisterModal: false,
        onModifyModal: false,
        onModal: false, // 주소 모달 on/off 여부
        resList: [],
        idxCrmCenter: -1,
        value: '',

        consStartDate: '', // 기간검색-시작날짜
        consEndDate: '', // 기간검색-종료날짜
        tabType: 'gangnam', // 탭 검색 (금일: today, 주간: week, 월간: month)

        // 상담사 일정 테이블 (api response용)
        counselorList: [],

        // 기본 시간 리스트 (시간이 10시까지 인 경우 20:30 이런식으로 추가만 해주면 된다.)
        timeLine: ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30'],

        // 각 시간별 일정 테이블 (로직 변환 최종 데이터)
        timeTable: [],

        today: '2020-01-01',
        todayYear: '2020',
        todayMonth: '01',
        todayDay: '01',
        todayDayOfWeek: '일',
        
        staffList: [],
        idxCrmStaff: -1,
        staffName: '',
        idxCrmReservation: -1,
        reservationStatus: '',
        clientType: '',
        idxCrmClient: -1,
        idxCrmCompany: -1,
        loading:true,
    }),

    watch: {
        consStartDate() {
            // console.log('typeof', typeof this.consStartDate)
            if (typeof this.consStartDate === 'object') {
                this.today = this.formatDate(this.consStartDate)
            } else if (typeof this.consStartDate === 'string') {
                this.today = this.consStartDate
            }
            this.todayYear = this.today.split('-')[0]
            this.todayMonth = this.today.split('-')[1]
            this.todayDay = this.today.split('-')[2]
            this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay())
            this.search()
        }
    },

    mounted() {
        this.defaultTable()
        
        if(sessionStorage.getItem("consStartDate") === null) {
            this.consStartDate = this.formatDate()
        } else {
            this.consStartDate = sessionStorage.getItem("consStartDate")
        }
        //this.asyncMount()
        this.search()

        // 오늘 날짜 세팅    
        this.today = this.formatDate()
        this.todayYear = this.today.split('-')[0]
        this.todayMonth = this.today.split('-')[1]
        this.todayDay = this.today.split('-')[2]
        this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay())
    },

    methods: {
        /* asyncMount() {
            this.search()
        },
 */
        mapTable() {
            // 상담일정 프론트 행에서 행 + 열로 변경 로직
            // 타임별로 object 추가
            for (var time of this.timeLine) {
                var tempData = {
                    time: time,
                    timeTable: []
                }

                // 상담사 별로 각 시간별 timetable에 있는지 확인
                // 있으면 각 데이터와 use키에 true
                // 없으면 use키에 false
                // 시간표에 색상은 use로 판별함
                for (var counselor of this.counselorList) {
                    var check = false
                    for (var eachTable of counselor.timeTable) {
                        if (eachTable.time === time) {
                            eachTable['use'] = true
                            eachTable['idxCrmStaff'] = counselor.idxCrmStaff // 20210111
                            eachTable['staffName'] = counselor.name // 20210111
                            eachTable["time"] = time // 20210111
                            tempData.timeTable.push(eachTable)
                            check = true
                            break
                        } else {
                            continue
                        }
                    }

                    if (check) {
                        continue
                    } else {
                        let staffOn = false // 상담사 일하는 시간 표시용 변수 false = 일안함 ture = 일함
                        if(counselor.workTime.indexOf(time) !== -1) {
                            staffOn = true
                        } else {
                            staffOn = false
                        }

                        tempData.timeTable.push({
                            use: false
                            , idxCrmStaff: counselor.idxCrmStaff // 20210111
                            , staffName: counselor.name // 210111
                            , time: time // 20210111
                            , workTime: staffOn
                        })
                    }
                }

                this.timeTable.push(tempData)
            }
        },

        search() {
            var params = {
                consEndDate: this.consStartDate,
                consStartDate: this.consStartDate,
                idxCrmCenter : localStorage.getItem('idxCrmCenter'),
                value: '',
                //type: 'd'
            }
             this.loading = true;

             this.axios.get('/api/v1/cons/list', {
                    params: params
                })
                .then(res => {
                    this.staffList = res.data.staffList 
                    this.resList = res.data.resList
                    if (this.resList.length > 0) {
                        this.counselorList = []
                        this.timeTable = []
                    } else {
                        this.defaultTable()
                        this.timeTable = []
                    }

                    for( var i=0;i<res.data.staffList.length;i++) {

                        var consData = {
                            idxCrmStaff: res.data.staffList[i].idx,
                            name: res.data.staffList[i].name,
                            workTime: res.data.staffList[i].work_time,
                            timeTable: []
                        }

                        for( var j=0;j<res.data.resList.length;j++) {

                            if(res.data.staffList[i].idx === res.data.resList[j].idxCrmStaff) {
                                // console.log('consTime', res.data.resList[j].consTime)
                                if(res.data.resList[j].consTime !== undefined) {
                                var consTimeArray = res.data.resList[j].consTime.split('/')

                                    for (var consTime of consTimeArray) {
                                        consData.timeTable.push({
                                            time: consTime,
                                            name: res.data.resList[j].name,
                                            type: res.data.resList[j].typeName,
                                            //count: 0,
                                            consNum: res.data.resList[j].consNum,
                                            idxCrmReservation: res.data.resList[j].idx ,
                                            idxCrmStaff: res.data.resList[j].idxCrmStaff ,
                                            reservationStatus: res.data.resList[j].reservationStatus ,
                                            clientType: res.data.resList[j].clientType ,
                                            idxCrmClient: res.data.resList[j].idxCrmClient ,
                                            idxCrmCompany: res.data.resList[j].idxCrmCompany ,
                                            companyName: res.data.resList[j].companyName ,
                                            workTime: true

                                        })
                                    }   
                                }
                            }
                        }

                        this.counselorList.push(consData)
                    }

                    this.mapTable()
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                }).finally(()=>{
                    this.loading = false;
                });
        },

        onCalendar() {
            console.log(document.getElementById('consult_schedule_date').childNodes[0].childNodes[2].style)
            document.getElementById('consult_schedule_date').childNodes[0].childNodes[2].style.display = 'block'
        },

        defaultTable () {
            this.counselorList = []
            /* this.counselorList = [{
                name: '-',
                timeTable: []
            },
            {
                name: '-',
                timeTable: []
            },
            {
                name: '-',
                timeTable: []
            },
            {
                name: '-',
                timeTable: []
            },] */
        },

        // 상담일지 미리보기 (모달 on)
        preview(subItem) {

            // console.log('모달 subItem', subItem)
            // console.log('session consStartDate', this.consStartDate)
            sessionStorage.setItem("consStartDate", this.formatDate(this.consStartDate));
            
            if(subItem.workTime === true) {
                if(subItem.idxCrmReservation !== undefined) {
                    // console.log('preview')
                    this.idxCrmReservation = subItem.idxCrmReservation
                    this.reservationStatus = subItem.reservationStatus
                    this.idxCrmClient = subItem.idxCrmClient
                    this.idxCrmCompany = subItem.idxCrmCompany
                    this.clientType = subItem.clientType
                    this.onModal = !this.onModal
                } else {
                    // console.log('register')
                    this.onRegisterModal = !this.onRegisterModal
                    this.idxCrmStaff = subItem.idxCrmStaff
                    this.staffName = subItem.staffName
                    this.time = subItem.time
                    //alert('부모 idxCrmStaff : ' + this.idxCrmStaff + ' staffName : ' + this.staffName + ' consStartDate : ' + this.consStartDate)
                    this.$refs.registerModal.radio()
                    this.$refs.registerModal.getStaffSchedule2(this.idxCrmStaff, this.staffName, this.consStartDate)
                }
            }
        },

        modifyPreview() {
            this.onModal = !this.onModal
            this.onModifyModal = !this.onModifyModal
            this.idxCrmReservation = this.idxCrmReservation
            this.$refs.modifyModal.getCustomerData(this.idxCrmClient)
            this.$refs.modifyModal.getReservation(this.idxCrmReservation)
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 요일 가져오기
        getDayOfWeek(index) {
            switch (index) {
                case 0:
                    return '일'
                case 1:
                    return '월'
                case 2:
                    return '화'
                case 3:
                    return '수'
                case 4:
                    return '목'
                case 5:
                    return '금'
                case 6:
                    return '토'
            }
        },

        // 다음 날로 옮기기
        nextDay() {
            // 다음 날이 다음달인 경우 달력 테이블 변경
            var date = new Date(this.today)
            var prevDate = new Date(this.today)
            // if ()
            // this.onMappingCalendar()

            date.setDate(date.getDate() + 1);

            this.today = this.formatDate(date)
            this.consStartDate = this.today
            console.log('today', this.today)
            console.log('consStartDate', this.consStartDate)
            this.todayYear = this.today.split('-')[0]
            this.todayMonth = this.today.split('-')[1]
            this.todayDay = this.today.split('-')[2]
            this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay())

            this.search()
        },

        // 이전 날로 옮기기
        prevDay() {
            var date = new Date(this.today)
            var prevDate = new Date(this.today)

            date.setDate(date.getDate() - 1);
            this.today = this.formatDate(date)
            this.consStartDate = this.today
            this.todayYear = this.today.split('-')[0]
            this.todayMonth = this.today.split('-')[1]
            this.todayDay = this.today.split('-')[2]
            this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay())

            this.search()
        },

        // 상담예약관리 상세정보 페이지로 이동
        detail(idx) {
            this.$router.push(`/convention/consult_reserve_detail?idx=${idx}`)
        },

        // 상담예약 등록페이지로 이동
        register() {
            this.$router.push('/convention/consult_reserve_register')
        },
    }
}
</script>
